import cx from "classnames";
import { HTMLAttributes, useState } from "react";
import "swiper/css/effect-fade";
import { EffectFade } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from "swiper/react";
import { Button } from "../components/Button";
import styles from "./SwiperCarousel.module.css";

import "swiper/css";
import useMediaQuery from "../hooks/useMediaQuery";
import { themeColor } from "../types";
import { Figure, FigureProps } from "./Figure";

interface SlideProps {
  image: FigureProps["image"];
  title: string;
  text: string;
  hasVideo?: boolean;
  url: string;
  colorIndex?: 0 | 1 | 2;
}

export interface SwiperCarouselProps extends HTMLAttributes<HTMLDivElement> {
  items: SlideProps[];
  handleCarouselBg: (color: themeColor) => void;
}

const Slide = ({ title, text, url, image, colorIndex = 0 }: SlideProps) => {
  return (
    <div className={styles.slide}>
      <Figure
        image={image}
        className={styles.slideImage}
        styled={false}
        width={500}
        height={500}
      />
      <div
        className={cx(styles.slideTextContainer, styles["color-" + colorIndex])}
      >
        <h3 className={styles.slideTitle}>{title}</h3>
        <p className={styles.slideText}>{text}</p>
        <Button
          variation="secondary-diap"
          className={styles.slideButton}
          url={url}
        >
          Bekijk het verhaal
        </Button>
      </div>
    </div>
  );
};

const bgs = ["light-green", "light-yellow", "light-blue"] as const;

export const SwiperCarousel = ({
  items,
  handleCarouselBg,
  ...rest
}: SwiperCarouselProps) => {
  const tabletSize = useMediaQuery("(min-width: 768px)");
  const [slideIndex, setSlideIndex] = useState(0);

  const handleSlideChange = (slide: SwiperClass) => {
    handleCarouselBg(bgs[slide.activeIndex]);
    setSlideIndex(slide.activeIndex);
  };

  const Prev = () => {
    const swiper = useSwiper();
    return (
      <button
        slot="container-start"
        className={cx(styles.prev, styles.navButton)}
        onClick={() => swiper.slidePrev()}
      >
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect opacity="0.08" width="56" height="56" rx="28" fill="#262429" />
          <path
            d="M28 20L29.41 21.41L23.83 27H36V29H23.83L29.41 34.59L28 36L20 28L28 20Z"
            fill="#0E0E0E"
          />
        </svg>
      </button>
    );
  };

  const Next = () => {
    const swiper = useSwiper();
    return (
      <button
        slot="container-end"
        className={cx(styles.next, styles.navButton)}
        onClick={() => swiper.slideNext()}
      >
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.08"
            width="56"
            height="56"
            rx="28"
            transform="matrix(-1 0 0 1 56 0)"
            fill="#262429"
          />
          <path
            d="M28 20L26.59 21.41L32.17 27H20V29H32.17L26.59 34.59L28 36L36 28L28 20Z"
            fill="#0E0E0E"
          />
        </svg>
      </button>
    );
  };

  const Dots = () => {
    const swiper = useSwiper();
    const handleDot = (index: number) => {
      setSlideIndex(index);
      swiper.slideTo(index);
    };
    return (
      <nav slot="container-end" className={styles.dotNav}>
        {items.map((item, index) => {
          return (
            <button
              key={index + item.title}
              onClick={() => handleDot(index)}
              className={cx(
                styles.dot,
                slideIndex === index && styles.dotActive
              )}
            >
              {index}
            </button>
          );
        })}
      </nav>
    );
  };

  return (
    <div className={styles.wrapper} {...rest}>
      <div className={styles.cardsWrapper}>
        <Swiper
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={tabletSize ? 40 : 20}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <Slide
                key={item.title}
                {...item}
                colorIndex={(index % 3) as 0 | 1 | 2}
              />
            </SwiperSlide>
          ))}
          {slideIndex > 0 && <Prev />}
          {slideIndex < items.length - 1 && <Next />}
          <Dots />
        </Swiper>
      </div>
    </div>
  );
};
