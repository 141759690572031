import React from "react";
import { Button } from "./Button";
import styles from "./CtaLine.module.css";
import { ScribbleLine } from "./ScribbleLine";
import cx from "classnames";

export type CtaLineProps = {
  title: string;
  buttonText: string;
  url: string;
  className?: string;
};

export const CtaLine = ({
  title,
  buttonText,
  url,
  className,
}: CtaLineProps) => {
  return (
    <div className={cx(styles.container, className)}>
      <ScribbleLine />
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <Button url={url}>{buttonText}</Button>
      </div>
    </div>
  );
};
