import { linkUrlGroq, linkUrlHrefGroq } from "../sanity/schemas/link";

export const richTextGroq = `
  ...,
  _type == "cta" => {
    "url": ${linkUrlGroq}
  },
  _type == "strongLink" => {
    "text": label,
    "linkType": select(
      url.type == 'link' => "internal",
      url.type == 'url' => "external",
      url.type == 'email' => "internal",
      url.type == 'phone' => "internal",
    ),
    "url": ${linkUrlGroq},
  },
  _type == "block" => {
    "markDefs": markDefs[]{
      _type == "link" => {
        ...,
        "url": ${linkUrlHrefGroq},
        "linkType": select(
          href.type == 'link' => "internal",
          href.type == 'url' => "external",
        ),
      }
    }
  },
`;

// "linkType": select(
//   url.type == 'link' => "internal",
//   url.type == 'url' => "external",
// ),
// "url": select(
//   url.type == 'link' => url.link->slug.current,
//   url.type == 'url' => url.url,
// )
