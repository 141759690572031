import styles from "./Flex.module.css";
import cx from "classnames";

export type FlexProps = {
  children: any;
  items?: 2 | 3 | 4 | 5 | 6;
  align?: "start" | "center" | "end";
};

export const Flex = ({ children, items = 3, align = "start" }: FlexProps) => {
  return (
    <div
      className={cx(
        styles.flex,
        styles["flex-" + items],
        styles["align-" + align]
      )}
    >
      {children}
    </div>
  );
};
