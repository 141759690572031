import cx from "classnames";
import { ReactNode } from "react";
import { MaxWidth, MaxWidthProps } from "../layouts/MaxWidth";
import { Spacing, SpacingProps } from "../layouts/Spacing";
import { Deco, DecoProps } from "./Deco";
import styles from "./SectionTitle.module.css";

export type SectionTitleProps = {
  children: ReactNode;
  size?: "xxl" | "xl" | "lg" | "md" | "sm";
  width?: MaxWidthProps["size"];
  align?: "center" | "left";
  deco?: DecoProps["deco"];
  spacing?: SpacingProps["size"];
  container?: MaxWidthProps["size"];
  className?: string;
  as?: "h1" | "h2" | "h3";
};

export const SectionTitle = ({
  children,
  align = "left",
  deco,
  className,
  size = "lg",
  as,
}: SectionTitleProps) => {
  const Component = as || "h2";
  return (
    <Component
      className={cx(styles.title, styles[size], styles[align], className)}
    >
      {deco ? <Deco deco={deco}>{children}</Deco> : <>{children}</>}
    </Component>
  );
};

export const SectionTitleContainer = ({
  children,
  align,
  deco,
  size,
  width,
  spacing,
  container,
  as,
}: SectionTitleProps) => {
  const props = { align, deco, size, spacing, container, as };
  return (
    <Spacing size={spacing}>
      <MaxWidth size={width}>
        <SectionTitle {...props}>{children}</SectionTitle>
      </MaxWidth>
    </Spacing>
  );
};
