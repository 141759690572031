import cx from "classnames";
import { HTMLAttributes, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from "swiper/react";
import { Button } from "../components/Button";
import styles from "./SwiperMobileCarousel.module.css";

import "swiper/css";
import useMediaQuery from "../hooks/useMediaQuery";
import { themeColor } from "../types";
import { Figure, FigureProps } from "./Figure";

interface SwiperMobileSlideProps {
  image: FigureProps["image"];
  title: string;
  text: string;
  hasVideo?: boolean;
  url: string;
  colorIndex?: 0 | 1 | 2;
}

export interface SwiperMobileCarouselProps
  extends HTMLAttributes<HTMLDivElement> {
  items: SwiperMobileSlideProps[];
  handleCarouselBg: (color: themeColor) => void;
}

const SwiperMobileSlide = ({
  title,
  text,
  url,
  colorIndex = 0,
}: SwiperMobileSlideProps) => {
  return (
    <div
      className={cx(styles.slideTextContainer, styles["color-" + colorIndex])}
    >
      <h3 className={styles.slideTitle}>{title}</h3>
      <p className={styles.slideText}>{text}</p>
      <Button
        variation="secondary-diap"
        className={styles.slideButton}
        url={url}
      >
        Bekijk het verhaal
      </Button>
    </div>
  );
};

export const SwiperMobileCarousel = ({
  items,
  ...rest
}: SwiperMobileCarouselProps) => {
  const tabletSize = useMediaQuery("(min-width: 768px)");
  const [slideIndex, setSlideIndex] = useState(0);

  const handleSlideChange = (slide: SwiperClass) => {
    setSlideIndex(slide.activeIndex);
  };

  const Dots = () => {
    const swiper = useSwiper();
    const handleDot = (index: number) => {
      setSlideIndex(index);
      swiper.slideTo(index);
    };
    return (
      <nav slot="container-end" className={styles.dotNav}>
        {items.map((item, index) => {
          return (
            <button
              key={index + item.title}
              onClick={() => handleDot(index)}
              className={cx(
                styles.dot,
                slideIndex === index && styles.dotActive
              )}
            >
              {index}
            </button>
          );
        })}
      </nav>
    );
  };

  return (
    <div className={styles.wrapper} {...rest}>
      <div className={styles.slideImages}>
        {items.map((item, index) => {
          const isActive = slideIndex === index;
          return (
            <Figure
              key={index}
              data-slide={index}
              image={item.image}
              className={cx(
                styles.slideImage,
                isActive && styles.slideImageActive
              )}
              styled={false}
              width={500}
              height={500}
            />
          );
        })}
      </div>
      <div className={styles.cardsWrapper}>
        <Swiper
          spaceBetween={tabletSize ? 40 : 20}
          slidesPerView={1.15}
          onSlideChange={handleSlideChange}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <SwiperMobileSlide
                key={item.title}
                {...item}
                colorIndex={(index % 3) as 0 | 1 | 2}
              />
            </SwiperSlide>
          ))}
          <Dots />
        </Swiper>
      </div>
    </div>
  );
};
