import cx from "classnames";
import { Button } from "../components/Button";
import { CenterTextContainer } from "../components/CenterText";
import { Deco } from "../components/Deco";
import { Figure, FigureProps } from "../components/Figure";
import { SectionTitle } from "../components/SectionTitle";
import { SwiperCarousel } from "../components/SwiperCarousel";
import { SwiperMobileCarousel } from "../components/SwiperMobileCarousel";
import { useBetterMediaQuery } from "../helpers/useBetterMediaQuery";
import { Spacing } from "../layouts/Spacing";
import { themeColor } from "../types";
import styles from "./StoriesCarousel.module.css";

type StorySlideProps = {
  image: FigureProps["image"];
  title: string;
  text: string;
  hasVideo?: boolean;
  url: string;
  colorIndex?: 0 | 1 | 2;
};

export const StorySlide = ({
  image,
  title,
  text,
  hasVideo,
  colorIndex = 0,
  url,
}: StorySlideProps) => {
  const icon = hasVideo ? "play" : undefined;

  return (
    <div className={styles.slide}>
      <Figure
        image={image}
        className={styles.slideImage}
        styled={false}
        width={500}
        height={500}
      />
      <div
        className={cx(styles.slideTextContainer, styles["color-" + colorIndex])}
      >
        <h3 className={styles.slideTitle}>{title}</h3>
        <p className={styles.slideText}>{text}</p>
        <Button
          variation="secondary-diap"
          icon={icon}
          className={styles.slideButton}
          url={url}
        >
          Bekijk het verhaal
        </Button>
      </div>
    </div>
  );
};

export type StoriesCarouselProps = {
  title: string;
  text: string;
  items: StorySlideProps[];
  handleCarouselBg: (color: themeColor) => void;
};

export const StoriesCarousel = ({
  title,
  text,
  items,
  handleCarouselBg,
}: StoriesCarouselProps) => {
  const matches = useBetterMediaQuery("(min-width: 900px)");

  return (
    <Spacing>
      <CenterTextContainer>
        <SectionTitle align="center">
          <Deco deco="top-center-scribble-magenta">{title}</Deco>
        </SectionTitle>
        <p className={styles.text}>{text}</p>
      </CenterTextContainer>
      {items && items.length === 1 && (
        <div className={styles.wrapper}>
          <StorySlide {...items[0]} />
        </div>
      )}
      {items && items.length > 1 && (
        <div className={styles.wrapper}>
          {matches ? (
            <SwiperCarousel items={items} handleCarouselBg={handleCarouselBg} />
          ) : (
            <SwiperMobileCarousel
              items={items}
              handleCarouselBg={handleCarouselBg}
            />
          )}
        </div>
      )}
    </Spacing>
  );
};
