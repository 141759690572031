export const storiesCarouselSchema = {
  type: "object",
  name: "storiesCarousel",
  title: "Stories Carousel",
  options: {
    collapsible: true,
    collapsed: false,
  },
  fields: [
    {
      name: "title",
      title: "title",
      type: "string",
      initialValue: "Verhalen uit de regio",
    },
    {
      name: "text",
      title: "text",
      type: "text",
      initialValue:
        "Laat je inspireren door de ervaringen van anderen in de regio Utrecht",
      rows: 3,
    },
    {
      name: "items",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "story" }],
        },
      ],
    },
  ],
};

export const storiesCarouselGroq = `
  ...,
  "items": items[]->{
    title,
    "text": quote,
    "image": mainImage,
    "hasVideo": video,
    "url": "verhalen/" + slug.current
  }
`;
