import React from "react";
import { MaxWidth } from "../layouts/MaxWidth";
import styles from "./CenterText.module.css";

export type CenterTextProps = {
  children: any;
};

export const CenterText = ({ children }: CenterTextProps) => {
  return <div className={styles.container}>{children}</div>;
};
export const CenterTextContainer = ({ children }: CenterTextProps) => {
  return (
    <div className={styles.container}>
      <MaxWidth size="sm">{children}</MaxWidth>
    </div>
  );
};
